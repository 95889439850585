<template>
    <div id="app">
        <div v-if="loaded && user && user.id" class="app-index">
            <div class="wrapper">
                <nav-bar></nav-bar>
                <div id="content">
                    <div class="row">
                        <div class="col-12 px-3 pt-2">
                            <router-view :key="$route.fullPath" v-if="loaded">
                            </router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <router-view :key="$route.fullPath" v-if="loaded">
            </router-view>
        </div>
        <block-ui v-if="processing || !loaded"/>
        <scroll-up></scroll-up>
        <alert-popup
            v-if="showAlert"
            @close="closeAlert"
        ></alert-popup>
    </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { mapState } from "vuex";
import scrollUp from '@/components/tools/ScrollUp';
let sl = require('@/languages/system_languages.json');
var languages = {};
for (let langCode in sl) {
    var lang = frontTools.importJSON(langCode + '.json', 'languages');
    if (!lang) lang = {}
    languages[langCode] = Object.assign(sl[langCode], lang);
}

export default {
    name: "App",
    components: {
      'scroll-up': scrollUp
    },
    data () {
        return {
            loaded: false,
            aliveTime: 1000 * 60 * 5,
            aliveStatus: false,
            showAlert: false,
            showModal: false,
            langOptions: [],
            interval: null
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            processing: state => state.main.processing,
        }),
        modified () {
            if (this.$store.getters.modified) {
                return true;
            }
        },
    },
    async created () {
        this.public = this.$router.currentRoute.meta.public;
        if (process.env.NODE_ENV=='production'){
            Sentry.init(
                {
                    //dsn: 'https://c12655a06f84415d87f5c981c7b289b0@sentry.io/1827065',
                    dsn: 'https://e801e80686414a4d987c1a717ec81b51@sentry.io/4505127351681024',
                    environment: process.env.NODE_ENV,
                    integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
                    beforeSend: (event, hint) => {
                        setTimeout(() => {
                            console.error(hint.originalException || hint.syntheticException)
                            let err = hint.originalException || hint.syntheticException;
                            if (err) console.logs.push([err.message, err.stack]);
                        }, 0);
                        return event;
                    }
                }
            );
        }

        window.onscroll = function (e) {
            $.each($(".notifications"),function() {
                let notPos;
                let notif = $(this);
                if (notif) {
                    notPos = notif.position();
                }
                let scroll;
                let modal = $('.modal');
                if (modal) {
                    scroll = $('.modal').scrollTop();
                }
                if (!scroll) {
                    scroll = $(document).scrollTop();
                }
                if (notPos && notPos && notPos.top < scroll) {
                    if (!notif.hasClass("not-float")) notif.addClass("not-float");
                } else {
                    if (notif.hasClass("not-float")) notif.removeClass("not-float");
                }
            });
        }

        this.setLanguage();
        const tr = (text, lang) => {
            if (!text) return text;
            if (typeof text === 'number') return text;
            //if (typeof text === 'object') return text;
            let l = lang;
            if (!l) l = api.language;
            try {
                if (!l) l = window.lang;
                if (Array.isArray(text)) {
                    let r = '';
                    for (let v of text) {
                        let t = v;
                        if (typeof v == 'object') {
                            t = tr(v.errorCode);
                        }
                        r += t + ' ';
                    }
                    return r;
                }
                if (text in languages[l]) return languages[l][text];
                if (typeof text === 'string') {
                    if (text.toUpperCase() in languages[l]) return languages[l][text.toUpperCase()];
                    if (tools.toTitleCase(text) in languages[l]) return languages[l][tools.toTitleCase(text)];
                }
            } catch (err) {
                console.log(err)
            }
            return text
        }
        window.tr = tr;
        Vue.mixin({
            methods: {
                tr: tr
            }}
        )
        await tools.fetchTable('language');
        if (api.tables.language) {
            for (let langCode in languages) {
                var lang = _.find(api.tables.language, (r) => r.Code == langCode);
                if (lang && lang.Translations) {
                    let translation = lang.Translations;
                    languages[langCode] = Object.assign(translation, languages[langCode]);
                }
            }
        }
    },
    async mounted () {
        this.interval = setInterval(() => {
            if (api.processing!=vueTools.processing) {
                vueTools.setProcessing(api.processing)
            }
            if (api.showAlert) {
                this.showAlert = true;
            } else {
                this.showAlert = false;
            }
        }, 100);
        let self = this;
        document.addEventListener('click', function(event) {
            if (!self.aliveStatus && self.user && self.user.UserName) {
                self.aliveStatus = true;
                api.get('/api/keep_alive/');
                setTimeout(() => {
                    self.aliveStatus = false;
                }, self.aliveTime)
            }

        });
        window.onbeforeunload = function (e) {
            if (self.modified) {
                e = e || window.event;
                if (e) {
                    e.returnValue = 'Sure?';
                }
                return 'Sure?';
            }
        };
        let user = api.currentUser;
        if (!api.currentUser) {
            user = await api.getCurrentUser();
        }
        if (user){
            this.$store.commit('setUser', user);
        }
        this.loaded = true;
    },
    watch: {
      $route(to) {
        if (to.meta.icon) {
          const link = document.querySelector("[rel='icon']")
          link.setAttribute('href', to.meta.icon)
        }
      }
    },
    methods: {
        setLanguage(lang) {
            if (!lang) {
                let navLang = (navigator.userLanguage || navigator.language).substring(0,2)
                if (navLang in languages) {
                    lang = navLang
                } else {
                    lang = 'en';
                }
            }
            window.lang = lang;
            this.lang = lang;
        },
        closeAlert () {
            this.showAlert = false;
            api.showAlert = null;
        },
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
};
</script>
