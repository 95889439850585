import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

import labels from 'chartjs-plugin-labels';

export default {
  name: 'pie-chart',
  extends: Pie,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    let options = this.options;
    if (options) {
        options.plugins = {
            labels: {
                position: 'outside',
            },
        }
        let self = this;
        options.onClick = function(e, d)  {
            setTimeout(() => {
                this._labels[0].options.position = 'outside';
            }, 100)
        };
    }

    this.renderChart(this.chartData, options)
  }
}