<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            class="agencies-report"
            endpoint="/reports/agencies_servicecost"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :templates="templates"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :current.sync="current" :slotsFields="[]"
            :notFilterable="options && options.notFilterable"
            :hideBlankColumns="true"
            :beforeRender="beforeRender"
            :afterRender="afterRender"
            :columnsClasses="columnsClasses"
            :cellClasses="cellClasses"
            :columnClick="columnClick"
            :hideSearch="true"
            :simpleTable="true"
            :hideToggle="true"
            :hideConfig="true"
            :hideDashboard="true"
            :hideCSV="true"
            :disableSort= "true"
            :columnStyles="{0: { cellWidth: 70} }"
            fileName="Service Rates"
            tableWidth="auto"
            :pdf="true"
            :getFileName="getFileName"
            ref="reportWindow"
            :headerMesssage="headerMesssage"
            @afterEdit="afterEdit"
        ></report-window>
        <service-modal
            v-if="service"
            :s.sync="service"
            :title="serviceTitle"
        ></service-modal>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
const serviceModal = importVueComp('components/reports', 'ServiceModal');
import { mapState } from "vuex";
export default {
    name: "service-costlist-report",
    props: ["options"],
    components: {
        ReportWindow,
        'service-modal': serviceModal,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        return {
            current: {},
            service: null,
            serviceTitle: null,
            ready: false,
            costByOptions: {
                  BASE_PERSON: 'Per Person, variable on base',
                  PERSON: 'Per Person, fixed',
                  SERVICE: 'Per Service, fixed',
                  BASE_SERVICE: 'Per Service, variable on base'
            },
            fields: [
                {
                    name: "CityId",
                    relation: "city",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "City",
                    addBlank: true,
                    sort: true,
                },
                {
                    name: "TransDate",
                    editor: "date",
                    label: "Date",
                },
                {
                    name: "MaxBase",
                    editor: "number",
                    decimals: 0,
                        label: "Max. Nbr of Paxs",
                },
                {
                    name: "pageSize",
                    editor: "select", defValue: 'a4',
                    options: [
                        {value: 'a1', label: 'A1'},
                        {value: 'a2', label: 'A2'},
                        {value: 'a3', label: 'A3'},
                        {value: 'a4', label: 'A4'},
                    ]
                },
            ],
            title: "Service Rates",
            headers: {
                StartDate: "Start Date",
                ServiceName: "Service",
                ServiceType: "Type",
                CurrencyId: "Currency",
                ServiceCategoryId: "Category",
                Comment: "Comments",
                ADT: 'SIB',
                StartDate: 'Valid From',
                EndDate: 'Valid To'
            },
            templates: [
                {
                    name: "StartDate",
                    callback: (row) => {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: 'Amount', callback: (row) => {
                        return tools.toNumber(row.Amount, 0, row.CurrencyId);
                    },
                },
            ],
            columnsClasses: {
                ServiceName: 'cursor-pointer headcol',
                ServiceType: 'headcol',
                StartDate: 'headcol set-color',
                EndDate: 'headcol set-color',
            },
            columnClick: {
                ServiceName: this.showService,
                StartDate: this.changeColor,
                EndDate: this.changeColor,
            },
            cellClasses: {
                ServiceName: [{
                    class: 'not-visible',
                    condition: row => row.notVisible,
                }],
                ServiceType: [{
                    class: 'not-visible',
                    condition: row => row.notVisible,
                }],
            },
            headerMesssage: {
                colspan: 16,
                html: false,
                rows: [
                    { text: "Rates per passenger per service unless indicated, in dollars, subject to change.", class: "white-space-nowrap" },
                    { text: "Excursion's rates don't include entrance fees.", class: "white-space-nowrap"},
                    { text: "Navigation's rates don't include transfers or Port fees.", class: "white-space-nowrap"},
                    { text: "Kindly notice that these rates are not valid for 24th, 25th, 31st December & 01st January, for this special dates the extra charge will be 100%.", class: 'text-danger white-space-nowrap'},
                ]
            }
        };
    },
    methods: {
        async beforeRender (report) {
            let headers = ['BlackOut', 'ChildDiscount', 'Closed', 'Comment', 'Cost', 'CreatedUTC', 'CreatedUserId',
              'CurrencyId', 'Data', 'EndDate', 'StartDate', 'ModifiedUTC', 'ModifiedUserId', 'Service',
              'ServiceCategoryId', 'ServiceId', 'ServiceName', 'ServiceType', 'id', 'syncVersion', 'Days'];
            if (report.serverColumnsHeader) {
                for (let column of report.serverColumnsHeader) {
                    if (headers.indexOf(column) == -1) {
                        let f = _.find(this.templates, (r) => r.name == column);
                        if (!f) {
                            this.templates.push({
                                name: column, callback: (row) => {
                                    return tools.toNumber(row[column], 0, row.CurrencyId);
                                }
                            })
                            this.columnsClasses[column] = 'text-right';
                        }
                    }
                }
                this.templates = Object.assign([], this.templates);
                this.columnsClasses = Object.assign({}, this.columnsClasses);
                this.removeColumn(report, 'Comment');
                this.removeColumn(report, 'StartDate');
                this.removeColumn(report, 'EndDate');
                this.removeColumn(report, 'ADT');
                //this.removeColumn(report, 'CHD');
                report.serverColumnsHeader.splice(2, 0, 'StartDate')
                report.serverColumnsHeader.splice(3, 0, 'EndDate')
                report.serverColumnsHeader.splice(5, 0, 'ADT')
                this.headerMesssage.colspan = report.serverColumnsHeader.length;
                this.headerMesssage = Object.assign({}, this.headerMesssage);
                if (this.current.CityId) {
                    let city = await api.get('/api/city/' + this.current.CityId)
                    if (city && city.ServicesMessage && city.ServicesMessage.length>20) {
                        this.headerMesssage = Object.assign({}, {
                            html: city.ServicesMessage,
                            colspan: report.serverColumnsHeader.length
                        });
                    }
                }
                //report.serverColumnsHeader.splice(6, 0, 'CHD')
            }
            for (let i in report.list) {
                let row = report.list[i];
                row.rowSpan = {};
                if (!row.notVisible) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.SupplierId == row.SupplierId && r.ServiceName == row.ServiceName && r.ServiceType == row.ServiceType;
                    }).length;
                    row.rowSpan.ServiceName = rowSpan;
                    row.rowSpan.ServiceType = rowSpan;
                }
                if (!row.notVisible2) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.SupplierId == row.SupplierId;
                    }).length;
                    row.rowSpan.SupplierName = rowSpan;
                }
            }

        },
        removeColumn (report, column) {
            var index = report.serverColumnsHeader.indexOf(column);
                if (index !== -1) {
                report.serverColumnsHeader.splice(index, 1);
            }
        },
        afterRender (report) {
            /*for (let i in report.list) {
                let row = report.list[i];
                if (!row.notVisible) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.SupplierId == row.SupplierId && r.StartDate == row.StartDate && r.EndDate == row.EndDate;
                    }).length;
                    let k = parseInt(i) + 1;
                    let div = $('#' + k + '-' + 'StartDate')[0];
                    if (div) {
                        $(div.parentElement).attr('rowspan', rowSpan)
                    }
                    let div2 = $('#' + k + '-' + 'EndDate')[0];
                    if (div2) {
                        $(div2.parentElement).attr('rowspan', rowSpan)
                    }
                }
                if (!row.notVisible2) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.SupplierId == row.SupplierId;
                    }).length;
                    let k = parseInt(i) + 1;
                    let div = $('#' + k + '-' + 'SupplierName')[0];
                    if (div) {
                        $(div.parentElement).attr('rowspan', rowSpan)
                    }
                }

            }*/
        },
        async showService (row, report) {
            let s = await api.get('/api/service/' + row.ServiceId);
            if (s) {
                this.service = s;
                this.serviceTitle = row.ServiceName;
            }
        },
        getFileName (type) {
            if (this.current.CityId) {
                let city = _.find(api.tables.city, (r) => r.id == this.current.CityId);
                if (city) return `${city.Name}.${type}`;
            }
            return `Service Rates.${type}`;
        },
        changeColor (row, report) {
            let value = 'bg-warning';
            if (!row.class) row.class = '';
            if (row.class.includes(value)) {
                row.class = row.class.replace(value, '');
            } else if (!row.class.includes(value)) {
                row.class += ' ' + value;
            }
            report.dataList = Object.assign([], report.dataList);
        },
        async afterEdit (fieldName) {
            if (fieldName=='CityId' && this.current.CityId) {
                let city = await api.get('/api/city/' + this.current.CityId)
                if (city && city.ServicesMessage && city.ServicesMessage.length>20) {
                    this.headerMesssage = Object.assign({}, {
                        html: city.ServicesMessage
                    });
                }
            }


        }
    }
};
</script>

<style>
.bg-warning .set-color {
    background-color: #ffc107!important;
}
.white-space-nowrap {
    white-space: nowrap;
}
#simpleTable p {
    margin-bottom: 0 !important;
}
</style>