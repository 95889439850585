import Vue from 'vue';
var EventBus = require('@/tools/event-bus').default;
import * as Sentry from '@sentry/browser';
import VueRouter from 'vue-router';
import store from '@/store/store';
const login = importVueComp('components/pages', 'Login');
const logout = importVueComp('components/tools', 'Logout');
const home = importVueComp('components/pages', 'Home');
const hotelCostListReport = importVueComp('components/reports', 'HotelCostListReport');
const serviceCostListReport = importVueComp('components/reports', 'ServiceCostListReport');

Vue.use(VueRouter);

let routes = [
      { path: '*', redirect: '/' },
      { path: '/', name: 'home', component: home, props: true },
      { path: '/login', component: login, name: 'logout' },
      { path: '/logout', component: logout, name: 'login' },
      { path: '/hotelcost', component: hotelCostListReport, name: 'hotelcost' },
      { path: '/servicecost', component: serviceCostListReport, name: 'servicecost' }
]


let extras = api.EXTRAS;
let extraRoutes = [];
for (let i in extras) {
    let extra = extras[i];
    let customRoutes;
    try {
        customRoutes = require(`@/extra/${extra}/router.js`).default;
    } catch(ex) {
        //console.log(ex)
    }

    if (customRoutes) {
        if  (customRoutes.remove) {
            for (let route of customRoutes.remove) {
                let i = _.findIndex(routes, (r) => r.name == route.name && r.menu == route.menu);
                if (i>=0) {
                    routes.splice(i, 1);
                }
            }
        }
        routes = customRoutes.routes.concat(routes);
    }
}

const router = new VueRouter({
    routes: routes
});



router.beforeEach(async function(to, from, next) {
    console.log(from.path, to.path)
    let modified = store.getters.modified || store.getters.isNew;
    if (modified && to.path != from.path && from.name != 'view-booking-version') {
        store.commit('setCallConfirmClose', true);
        store.commit('setToPath', to);
        return;
    }
    let currentUser = api.currentUser;
    if (to.path == '/login') {
        if (!currentUser) {
            currentUser = await api.getCurrentUser();
        }
        if (currentUser) {
            next('/');
        } else {
            next();
        }
    } else if (to.path == '/logout') {
        next();
        return;
    } else {
        if (!currentUser) {
            let user = await api.getCurrentUser();
            if (!user) {
                next('/login');
                return;
            } else {
                store.commit('setUser', user);
                Sentry.setUser({"name": user.UserName});
            }
        }
        let matchedPath = frontTools.getMatchedPath(to);
        //let userAccess = tools.canAccess(store.state.main.user, 'view', 'GET', matchedPath);
        let userAccess = true;
        if (to.path=='/') {
            next();
            return;
        } else if (userAccess) {
            next();
            return;
        } else {
            return;
        }
    }
});

export default router;
