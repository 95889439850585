<template>
    <div :id="'quill-' + _uid" @click="setEdit">
        <div id="text" v-show="value && !edit" class="text-edit-show py-0">
            <text-rows-style :text="value"></text-rows-style>
        </div>
        <div id="text" v-show="!value && !edit" class="text-edit-show">
            <p><i>{{tr('Input Text')}}</i></p>
            <br>
            <br>
        </div>
        <div id="edit" class="edit-quill-editor" v-if="quillFocus && edit" >
            <text-area-style
                :disabled="disabled"
                :class="addClass"
                :show="quillFocus"
                :text.sync="value"
                :pasteText="pasteText"
                :id="id"
                :extras="extras"
                :maxHeight="maxHeight"
            ></text-area-style>
        </div>
    </div>
</template>

<script>
import '@/quill/quill.show.css';
import { mapState } from 'vuex';
export default {
    name: 'text-area-edit-style',
    props: ['text', 'pasteText', 'id', 'maxHeight', 'addClass', 'disabled', 'extras'],
    data () {
        return {
            value: null,
        }
    },
    computed:{
        ...mapState({
            quillFocus: state => state.quill.quillFocus,
            quillStatus: state => state.quill.quillStatus,
        }),

        edit () {
            return this.quillFocus ==  'quill-' + this._uid;
        }
    },
    mounted () {
        this.value = this.text;
    },
    watch: {
        value () {
            this.$emit('update:text', this.value);
        },
        text () {
            this.value = this.text;
        },
        quillFocus () {
            let self = this;
            if (this.quillFocus==false) {
                let div = $(this.$el).find('#edit').find('.ql-toolbar')[0];
                if (div) {
                    div.remove();
                }
            }
        }
    },
    methods: {
        setEdit () {
            this.$store.commit('setQuillFocus', 'quill-' + this._uid);
            this.$store.commit('setQuillStatus', 'quill-' + this._uid);
        }
    }
}
</script>
