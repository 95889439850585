import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins


export default {
  name: 'bar-chart',
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    var options = this.options;
    options.plugins = {
      labels: {
          render: (data) => {
              if (this.options.dataLabels) {
                  return this.options.dataLabels(data.value);
              }
              if (!data.value) return '';
              return data.value;
          },
          fontStyle: this.options.dataLabelsStyle? this.options.dataLabelsStyle: 'normal',
      },
    }
    if (this.options.dataLabelsColor) options.plugins.labels.fontColor = this.options.dataLabelsColor;
    if (this.options.dataLabelsSize) options.plugins.labels.fontSize = this.options.dataLabelsSize;
    let self = this;
    options.onClick = function (e, d) {
        setTimeout(() => {
            this._labels[0].options.render = 'value';
        }, 100)
    };
    options.onResize = function (chart, d) {
        setTimeout(() => {
            chart.update();
            chart._labels[0].options.render = 'value';
        }, 10)

    }
    this.renderChart(this.chartData, options)
  },
}