<template>
    <div :class="{'row-area-style': !nonClass}" @click="$emit('edit')">
    </div>
</template>

<script>
export default {
    name: 'text-rows-style',
    props: ['text', 'nonClass', 'langCode'],
    mounted () {
        if (this.text) {
            if (this.langCode) {
                this.$el.innerHTML = this.text;
                let el = $(this.$el);
                for (let child of el[0].childNodes) {
                    if (child.innerText) {
                        let values = [];
                        let list = child.innerText.split('\n');
                        for (let v of list) {
                              values.push(this.trl(v));
                        }
                        child.innerText = values.join('\n');
                    }
                }
            } else {
                this.$el.innerHTML = this.text.split('\n').join('<br>')
            }
        }
    },
    methods: {
        trl (t) {
            if (!this.langCode) return t;
            return tr(t, this.langCode);
        },

    },
    watch: {
        text (val) {
            if (this.text) this.$el.innerHTML = this.text.split('\n').join('<br>');;
        }
    },
}
</script>
