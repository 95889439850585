<template>
    <div v-if="user">
    </div>
</template>

<script>

import { mapState } from 'vuex';
export default {
    name: 'home',
    components: {
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
}
</script>

