<template>
    <div class="modal fade bd-example-modal-lg" id="service-modal" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="serviceModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-optionals">
            <div class="form-row">
                <div :class="{'col-md-7': images.length, 'col-md-12': !images.length}">
                  <text-rows-style classStyle="text-justify" tag="p" :text="description"></text-rows-style>
                  <ul>
                  </ul>
                </div>
                <div class="col-md-5 image optional-image" v-if="images.length>0">
                    <lin-gallery :items="images" v-if="images.length>1" :height="200"></lin-gallery>
                    <async-img :url="images[0].src" class="d-block w-100" v-else-if="images.length==1"></async-img>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
const linGallery = importVueComp('components/tools', 'LinGallery');
export default {
    name: 'service-modal',
    mixins: [escape],
    components: {
      'lin-gallery': linGallery,
    },
    props: ['s', 'title'],
    mounted () {
        $('#service-modal').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        close () {
            this.$emit('update:s', null);
            $('#service-modal').modal('hide');
        },
    },
    computed:{
        imageUrl () {
            if (this.s && this.s.Image && this.s.Image.url) return this.s.Image.url;
        },
        description () {
            let d = _.find(this.s.ServiceDescriptions, (r) => r.Language.Code == 'en');
            if (d) return d.Description;
        },
        images () {
            let res = [];
            if (this.s && this.s.Image && this.s.Image.url) {
                res.push({id: this.s.ImageId, src: this.s.Image.url, thumbnail: this.s.Image.url});
            };
            for (let image of this.s.ServicePhotos) {
                if (!image.Image) continue;
                res.push({id: image.ImageId, src: image.Image.url, thumbnail: image.Image.url});
            }
            return res;
        }
    },
}
</script>


