<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-dark fixed-top py-0">
            <img src="@/extra/custom/img/logo-white.png" width="150" class="cursor-pointer" @click="setPath({path: '/'})">
            <div class="collapse navbar-collapse ml-3" id="navbarSupportedContent">
                <a class="cursor-pointer" @click="setPath({path: '/hotelcost'})">
                    <span class="mr-4 d-none d-lg-inline text-white">
                        {{tr('Hotel Rates')}}
                    </span>
                </a>
                <a class="cursor-pointer" @click="setPath({path: '/servicecost'})">
                    <span class="mr-2 d-none d-lg-inline text-white">
                        {{tr('Service Rates')}}
                    </span>
                </a>
            </div>
            <div class="collapse navbar-collapse ml-3" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown no-arrow">
                        <a v-if="user" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline"> {{ user.UserName }} </span>
                            <img class="img-profile rounded-circle" :src="user.Image.url" v-if="user.ImageId">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a class="dropdown-item cursor-pointer" @click="showSelectLanguage">
                                <font-awesome-icon icon="language" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Language')}}
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item cursor-pointer" @click="logout">
                                <font-awesome-icon icon="sign-out-alt" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Sign out')}}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <select-value
            v-if="selectLanguage"
            @save="setLanguage"
            :show.sync="selectLanguage"
            text="Select Language"
            :fieldOptions="languages"
            :defValue="defLanguage"
        ></select-value>
    </div>
</template>

<script>
import '@/css/app.scss';
const menuItem  = importVueComp('components/tools', 'VueMenuItem');
import { mapState } from "vuex";
var sl = require('@/languages/system_languages.json');
var EventBus = require('@/tools/event-bus').default;
const modules = vueTools.importModule('modules', 'tools').default;
console.log(1)
export default {
    name: 'nav-bar',
    components: {
        'menu-item': menuItem,
    },
    data () {
        return {
            history: [],
            marks: [],
            selectLanguage: false,
            languages: [{label: 'es', value: 'es'}, {label: 'en', value: 'en'}],
            defLanguage: null,
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        this.defLanguage = localStorage.getItem('user-language');
    },
    methods: {
        logout () {
            this.$router.push("/logout");
        },
        showSelectLanguage () {
            this.selectLanguage = true;
        },
        showSelectActivitiesView () {
            this.selectActivitiesView = true;
        },
        setLanguage (value) {
            localStorage.setItem('user-language', value);
            this.selectLanguage = false;
        },
        setPath (r) {
            if (r.endpoint) {
                if (this.$router.currentRoute.name == r.endpoint) return;
                this.$router.push({name: r.endpoint});
            } else if (r.path) {
                if (this.$router.currentRoute.path == r.path) return;
                this.$router.push({path: r.path});
            }
        },
    },
};
</script>

<style>
    .img {
      fill: green !important;
    }

</style>
